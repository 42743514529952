import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIdentity } from "../contexts/IdentityContext";
import Loading from "./Loading";


const DisconnectedRoute = (props: { children: JSX.Element}) => {
    const { user, isLogged } = useIdentity();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && isLogged) {
            navigate('/dashboard');
        }
    }, [navigate, user, isLogged]);

    return !isLogged ? props.children : <Loading />;
};

export default DisconnectedRoute;