import moment from "moment";
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Message } from "../types";
import axiosInstance from '../utils/axiosSetup';
import dataLoader from '../utils/dataLoader';
import { useApp } from "./AppContext";
import { useIdentity } from "./IdentityContext";
import { useSocket } from "./SocketContext";

interface Props {
    messages: Message[];
    setCurrentMessage: React.Dispatch<Message>;
    messagesLoading: boolean;
}

const MessagesContext = createContext<Props | null>(null);

const useMessages = () => {
    const currentMessagesContext = useContext(MessagesContext);
    if (!currentMessagesContext) {
        throw new Error(
            "useMessages has to be used within <MessagesContext.Provider>"
        );
    }
    
    return currentMessagesContext;
};

function MessagesProvider(props: {children: JSX.Element}) {
    
    const { t } = useTranslation();
    const { hasNetwork } = useApp();
    const { isLogged } = useIdentity();
    const { socketIO } = useSocket();

    const [loading, setLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [currentMessage, setCurrentMessage] = useState<Message | null>(null);

    const messagesModalRef = useRef<HTMLDivElement>(null);

    const loadMessages = () => {
        const httpReq = dataLoader(`messages/user`, {
            'messages': setMessages
        }, setLoading);

        return httpReq;
    };

    useEffect(() => {
        let httpReq: AbortController | null = null;

        if (hasNetwork && isLogged) {
            httpReq = loadMessages();
        }

        if (messagesModalRef.current) {
            M.Modal.init(messagesModalRef.current);
        }

        return (() => {
            if (httpReq) {
                httpReq.abort();
            }
        });
    }, [hasNetwork, isLogged]);

    useEffect(() => {
        if (socketIO && socketIO.connected) {
            socketIO.on("getMessage", (data) => {
                setMessages(prev => [data].concat(...prev));
            });
        }

        return () => {
            if (socketIO && socketIO.connected) {
                socketIO.off("getMessage");
            }
        };
    }, [socketIO]);

    useEffect(() => {
        if (messages.length) {
            for (const message of messages) {
                if (!message.is_read) {
                    if ("vibrate" in navigator) {
                        navigator.vibrate(400);
                    }
                    setCurrentMessage(message);
                    break;
                }
            }
        }
    }, [messages]);

    useEffect(() => {
        if (currentMessage) {
            const modalMessagesInst = M.Modal.getInstance(messagesModalRef.current as HTMLDivElement);
            
            modalMessagesInst.options.onCloseStart = () => {

                if (!currentMessage.is_read) {
                    
                    axiosInstance.put(`messages`, {...currentMessage, is_read: true}).then(function (res) {
                        
                        if (res.data.code === 200) {
                            const messagesUpdate = [...messages];
                            messagesUpdate[messagesUpdate.findIndex(message => message.id === currentMessage.id)].is_read = true;
                            setMessages(messagesUpdate);
                            if (socketIO && socketIO.connected) {
                                socketIO.emit('messages:read', res.data.data);
                            }
                        }
                        else {
                            M.toast({html: 'Une erreur est survenue lors de l\'enregistrement', classes: 'red'});
                        }
                    }).catch(function (error) {
                        M.toast({html: 'Serveur indisponible', classes: 'red'});
                        console.log(error);
                    });
                }
            };
            modalMessagesInst.options.onCloseEnd = (e) => {
                setCurrentMessage(null);
            };
        }
        
    }, [currentMessage, socketIO]);

    useEffect(() => {
        if (currentMessage) {
            const modalMessagesInst = M.Modal.getInstance(messagesModalRef.current as HTMLDivElement);
            if (!modalMessagesInst.isOpen) {
                modalMessagesInst.open();
            }
        }
    }, [currentMessage]);

    return (
        <MessagesContext.Provider value={{messages: messages, setCurrentMessage: setCurrentMessage, messagesLoading: loading}} {...props}>
            <div id="messages-modal" className="modal" ref={messagesModalRef}>

                {currentMessage !== null ? 
                    <div className="modal-content">
                        
                        <ul key={currentMessage.id} className="collection">
                            <li className="collection-item">
                                <p>
                                    {moment(currentMessage.created_at).format('DD.MM.Y HH:mm')} de {currentMessage.sender?.firstname} {currentMessage.sender?.lastname}
                                    {!messages[messages.findIndex(message => message.id === currentMessage.id)].is_read ? <span className="badge new">{t('new')}</span> : null}
                                </p>
                                <p>{messages[messages.findIndex(message => message.id === currentMessage.id)].body}</p>
                            </li>
                        </ul>
                            
                    </div>
                    : null}

                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect btn-flat">{t('close')}</a>
                </div>
            </div>

            {props.children}
        </MessagesContext.Provider>
    );
}

export { MessagesProvider, useMessages };

